export const menuData = [
  {
    id: 1,
    title: "Transformations",
    links: [
      {
        id: 1,
        title: "Next Level Creators",
        link: "training",
        target: "",
        rel: "",
      },
      {
        id: 2,
        title: "30 Day Course Creator",
        link: "https://www.contentcreator.com/start-a-6-7-figure-course-in-30-days",
        target: "blank",
        rel: "referrer",
      },
      {
        id: 3,
        title: "Earn The Deal",
        link: "https://www.contentcreator.com/earn-the-deal",
        target: "blank",
        rel: "referrer",
      },
      {
        id: 4,
        title: "1 Day Script",
        link: "https://www.contentcreator.com/1-day-script",
        target: "blank",
        rel: "referrer",
      },
      {
        id: 5,
        title: "14 Day Filmmaker",
        link: "/14-day-filmmaker",
        target: "",
        rel: "",
      },
      {
        id: 6,
        title: "Six Figure Live Streamer",
        link: "https://www.contentcreator.com/6-figure-live-streamer",
        target: "blank",
        rel: "referrer",
      },
      {
        id: 7,
        title: "6 Figure Fitness Filmmaker",
        link: "https://www.contentcreator.com/6-figure-fitness-filmmaker",
        target: "blank",
        rel: "referrer",
      },
    ],
  },
  {
    id: 2,
    title: "Success Stories",
    links: [
      // {
      //   id: 1,
      //   title: "Proof",
      //   link: "/",
      // },
      {
        id: 2,
        title: "100+ Success Stories",
        link: "/success-stories",
        target: "",
        rel: "",
      },
    ],
  },
  {
    id: 3,
    title: "Resources",
    links: [
      // {
      //   id: 1,
      //   title: "Resources",
      //   link: "/",
      // },
      {
        id: 2,
        title: "Free Community",
        link: "https://www.facebook.com/groups/thebusinessofvideoproduction",
        target: "blank",
        rel: "referrer",
      },
    ],
  },
  {
    id: 4,
    title: "About",
    links: [
      {
        id: 1,
        title: "Paul Xavier",
        link: "/about",
        target: "",
        rel: "",
      },
      // {
      //   id: 2,
      //   title: "Mission",
      //   link: "/",
      // },
      // {
      //   id: 3,
      //   title: "Contact",
      //   link: "/",
      // },
    ],
  },
]
